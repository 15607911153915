<template>
  <c-box style="position: relative" bg="vc-white.20">
    <c-flex
      class="header"
      align="['start','start', 'space-between']"
      :flexDirection="['column', 'column', 'row']"
      justify="space-between"
      mt="4"
      mb="4"
    >
      <c-flex
        pt="10px"
        justify="['space-between','start', 'space-between']"
        align="center"
      >
        <c-heading
          pt="2"
          fontSize="2xl"
          fontWeight="700"
          mr="3"
          ml="3"
          :mb="['2', '2', '0']"
        >
          Explore
        </c-heading>
      </c-flex>
      <c-flex align="center" justify="['start','end', 'space-between']">
        <search @hide-sidebar="handleHideSidebar" />
        <c-link
          ml="15px"
          mt="15px"
          class="saved"
          @click="$router.push('/app/messaging')"
        >
          <svg class="svgmessage">
            <use href="@/assets/icons/comment-fill.svg#comment" />
          </svg>
          <span class="custom_badge">{{ unreadMessageCount }}</span>
          <!-- <c-icon size="6" color="vc-orange.300" name="message" /> -->
        </c-link>
        <!--        <c-link ml="20px" mt="15px" class="saved" @click="moveToSavedPage">-->
        <!--          <c-icon size="6" color="vc-orange.300" name="bookmark" />-->
        <!--        </c-link>-->
        <c-box class="notification" mt="15px" ml="8">
          <Notification />
        </c-box>
      </c-flex>
    </c-flex>

    <c-flex :flexDirection="['column', 'column', 'row']">
      <c-flex w="70%" h="100%" :flex="hideSidebar ? '1' : '3'">
        <router-view />
      </c-flex>
      <c-flex v-if="!hideSidebar" ml="5" :flex="hideSidebar ? '0' : '3'">
        <sidebar />
      </c-flex>
    </c-flex>
  </c-box>
</template>

<script>
import { mapState } from 'vuex';
import Notification from '@/views/App/components/NotificationDialog.vue';
import Sidebar from './Sidebar.vue';
import Search from '@/views/App/Explore/Search.vue';

export default {
  name: 'Layout',
  components: {
    Search,
    Notification,
    Sidebar,
  },
  data() {
    return {
      isDrawerOpen: false,
      hideSidebar: false,
    };
  },
  methods: {
    moveToSavedPage() {
      this.$router.push('/saved');
    },
    handleHideSidebar(status) {
      this.hideSidebar = status;
    },
    closeDrawer() {
      this.isDrawerOpen = false;
    },
  },
  computed: {
    ...mapState('messaging', {
      unreadMessageCount: (state) => state.unreadMessageCount,
    }),
  },
  watch: {
    $route(to) {
      // Perform actions here
      if (to.path === '/app/explore' || to.path === '/app/explore/') {
        this.handleHideSidebar(false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f9fbfd;
  padding-bottom: 20px;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .saved {
    position: absolute;
    right: 50px;
  }

  .menu {
    position: absolute;
    right: 10px;
    margin-top: 8px;
  }

  .notification {
    position: absolute;
    right: 20px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .saved {
    right: 0;
  }

  .menu {
    display: none;
  }

  .header {
    position: sticky;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .menu {
    display: none;
  }
  .saved {
    top: 20px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .menu {
    display: none;
  }
  .saved {
    top: 20px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .menu {
    display: none;
  }
}

svg.svgmessage {
  width: 23px;
  height: 23px;
  fill: #6b7a84;
}

.custom_badge {
  position: absolute;
  top: 18px;
  right: 45px;
  background: #f00;
  color: #fff !important;
  border-radius: 50%;
  width: 18px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.6rem;
}
</style>
