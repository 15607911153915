import { render, staticRenderFns } from "./Search.vue?vue&type=template&id=ba92cb4c&scoped=true"
import script from "./Search.vue?vue&type=script&lang=js"
export * from "./Search.vue?vue&type=script&lang=js"
import style0 from "./Search.vue?vue&type=style&index=0&id=ba92cb4c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba92cb4c",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CIcon: require('@chakra-ui/vue').CIcon, CInputLeftElement: require('@chakra-ui/vue').CInputLeftElement, CInput: require('@chakra-ui/vue').CInput, CInputGroup: require('@chakra-ui/vue').CInputGroup, CText: require('@chakra-ui/vue').CText, CFlex: require('@chakra-ui/vue').CFlex, CImage: require('@chakra-ui/vue').CImage, CButton: require('@chakra-ui/vue').CButton})
